@tailwind base;
@tailwind components;
@tailwind utilities;

/*SharePoint style*/
.sharepointContent h1 {
  font-size: calc(1.8vw + 1.8vh);
  font-weight: bold;
  padding: 0;
  margin-bottom: 1.5vh;
}
.sharepointContent h2 {
  font-size: calc(1.6vw + 1.6vh);
  font-weight: bold;
  padding: 0;
  margin-bottom: 1.5vh;
}
.sharepointContent h3 {
  font-size: calc(1.5vw + 1.5vh);
  font-weight: bold;
  padding: 0;
  margin-bottom: 1.5vh;
}
.sharepointContent h4 {
  font-size: calc(1.4vw + 1.4vh);
  font-weight: bold;
  padding: 0;
  margin-bottom: 1.5vh;
}
.sharepointContent h5 {
  font-size: calc(1.3vw + 1.3vh);
  font-weight: bold;
  padding: 0;
  margin-bottom: 1.5vh;
}
.sharepointContent h6 {
  font-size: calc(1.2vw + 1.2vh);
  font-weight: bold;
  padding: 0;
  margin-bottom: 1.5vh;
}
.sharepointContent p {
  font-size: calc(1.2vw + 1.2vh);
  padding: 0;
  margin-top: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-bottom: 1.5vh !important;
}
.sharepointContent blockquote {
  font-size: calc(1.2vw + 1.2vh);
  font-weight: bold;
  font-style: italic;
  text-align: center;
  padding: 0;
  margin-top: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-bottom: 1.5vh !important;
}
.sharepointContent pre {
  font-size: calc(1.2vw + 1.2vh) !important;
  padding: calc(0.2vw + 0.2vh);
  white-space: pre-wrap;
  word-wrap: break-word;
  background-color: rgb(255, 255, 255, 0.15);
  margin-top: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-bottom: 1.5vh !important;
}
.sharepointContent table {
  font-size: calc(1vw + 1vh);
}
.sharepointContent table td {
  border: 1px solid rgb(255, 255, 255, 0.25);
  padding: calc(0.2vw + 0.2vh);
}

/*end sharepoint*/

.scroll-text {
  /* animation properties */
  -moz-transform: translateX(20%);
  -webkit-transform: translateX(20%);
  transform: translateX(20%);

  -moz-animation: my-animation 15s linear infinite;
  -webkit-animation: my-animation 15s linear infinite;
  animation: my-animation 15s linear infinite;
}

/* for Firefox */
@-moz-keyframes my-animation {
  from {
    -moz-transform: translateX(20%);
  }
  to {
    -moz-transform: translateX(-100%);
  }
}

/* for Chrome */
@-webkit-keyframes my-animation {
  from {
    -webkit-transform: translateX(20%);
  }
  to {
    -webkit-transform: translateX(-100%);
  }
}

@keyframes my-animation {
  from {
    -moz-transform: translateX(20%);
    -webkit-transform: translateX(20%);
    transform: translateX(20%);
  }
  to {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

/*temporary solution for casaone suter template*/
@font-face {
  font-family: SuterSans;
  src: url(templates/casaone/suter/fonts/SuterSans-Book.ttf);
  src: url(templates/casaone/suter/fonts/SuterSans-Book.eot?#iefix)
      format(‘embedded-opentype’),
    url(templates/casaone/suter/fonts/SuterSans-Book.woff) format(‘woff’),
    url(templates/casaone/suter/fonts/SuterSans-Book.ttf) format(‘truetype’);
  font-weight: 300;
}
@font-face {
  font-family: SuterSans;
  src: url(templates/casaone/suter/fonts/SuterSans-Medium.ttf);
  src: url(templates/casaone/suter/fonts/SuterSans-Medium.eot?#iefix)
      format(‘embedded-opentype’),
    url(templates/casaone/suter/fonts/SuterSans-Medium.woff) format(‘woff’),
    url(templates/casaone/suter/fonts/SuterSans-Medium.ttf) format(‘truetype’);
  font-weight: 400;
}
@font-face {
  font-family: SuterSans;
  src: url(templates/casaone/suter/fonts/SuterSans-Bold.ttf);
  src: url(templates/casaone/suter/fonts/SuterSans-Bold.eot?#iefix)
      format(‘embedded-opentype’),
    url(templates/casaone/suter/fonts/SuterSans-Bold.woff) format(‘woff’),
    url(templates/casaone/suter/fonts/SuterSans-Bold.ttf) format(‘truetype’);
  font-weight: 600;
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.clamp ol {
  list-style-type: decimal;
}
.clamp ul {
  list-style-type: disc;
}

.clamp ol,
.clamp ul {
  padding-left: 1.7em;
}
